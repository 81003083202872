import React from "react";
import "./AboutUs.css";
import AboutUsBanner from "../../assets/images/AboutUsBanner.png";
import CompassImg from "../../assets/logo/Compass_RoseGold.png";
import AboutUsImg1 from "../../assets/images/AboutUsImg1.png";
import AboutUsImg2 from "../../assets/images/AboutUsImg2.png";
import AboutUsImg3 from "../../assets/images/AboutUsImg3.png";
import EmployeeImg1 from "../../assets/images/employee1.png";
import EmployeeImg2 from "../../assets/images/employee2.png";
import EmployeeImg3 from "../../assets/images/employee3.png";
import EmployeeImg4 from "../../assets/images/employee4.png";
import EmployeeImg5 from "../../assets/images/employee5.png";
import EmployeeImg6 from "../../assets/images/employee6.png";

export default function AboutUs() {
  const employees = [
    {
      imgSrc: EmployeeImg1,
      thName: "ภูวณัฐ  สุภาพกุล",
      enName: "Poowanat Suphapkul",
      position: "Managing Director",
    },
    {
      imgSrc: EmployeeImg2,
      thName: "ธีรพัฒน์ เลิศสิริประภา",
      enName: "Teerapat Lertsiriprapa",
      position: "Advisory Committee (Japanese Cuisine Specialist)",
    },
    {
      imgSrc: EmployeeImg3,
      thName: "ดลฤทัย ชิตพงศ์ ",
      enName: "Donluthai Chitapong",
      position: "Executive Assistant to MD",
    },
    {
      imgSrc: EmployeeImg4,
      thName: "สุจิตรา เกิดภาพิพัฒน์    ",
      enName: "Sujitra Kirdpapipat ",
      position: "Event Project Manager",
    },
    {
      imgSrc: EmployeeImg5,
      thName: "ณฐกร เกิดภาพิพัฒน์",
      enName: "Nathakorn Kirdpapipat",
      position: "Tour Operation Manager",
    },
    {
      imgSrc: EmployeeImg6,
      thName: "วชิรวิทย์ ทับพิมล",
      enName: "Wachirawit Tuppimon",
      position: "Marketing Executive",
    },
  ];
  function generateEmployeeItem() {
    return employees.map((employee) => (
      <div className="employee-item">
        <img src={employee.imgSrc} alt="" />
        <p>{employee.thName}</p>
        <p>{employee.enName}</p>
        <div className="pink-line"></div>
        <p>{employee.position}</p>
      </div>
    ));
  }
  return (
    <div className="app-background-container about-us-bg">
      <div className="row about-us-banner">
        <img src={AboutUsBanner} alt="about us banner" width="100%" />
        <h1>Our Story</h1>
      </div>
      <div className="about-us-content-container">
        <img className="about-us-compass-img" src={CompassImg} alt="" />
        <p>
          <strong>Omakase Voyage </strong>
          ได้เริ่มต้นเกิดขึ้นมาจากการการรวมตัวกันของผู้ที่มีความชำนาญในการเดินทาง
          การดูแลประสานงาน รวมไปถึงมีความเชี่ยวชาญในการท่องเที่ยว อาหาร
          และวัฒนธรรมของประเทศญี่ปุ่น โดยเราเรียกว่าทีม “Experience Specialists”
          <br />
          <br />
          โอมาคาเสะ (omakase) เป็นภาษาญี่ปุ่นที่มีความหมายถึง
          “การคัดเลือกคุณค่าและสิ่งที่ดีที่สุดเพื่อมอบให้กับใครสักคน”
          ซึ่งนั่นคือ Concept และ Idea ที่ทำให้เกิด Omakase Voyage ขึ้นมา
          <br />
          <br />
          ทีมของเราได้ถูกรวมตัวกันขึ้นด้วยเป้าหมายจะทำหน้าที่ดูแล ให้คำปรึกษา
          และช่วยเหลือผู้ที่มี
          ความประสงค์ที่จะประสานงานร่วมกับประเทศญี่ปุ่นในทุกๆมิติ
          ผ่านการเดินทางเพื่อการธุรกิจ และท่องเที่ยว
          เราคือทีมที่ทุกท่านสามารถเชื่อได้ว่าเราจะสามารถสรรค์สร้าง
          และมอบประสบการณ์การเดินทาง ท่องเที่ยว
          และประสานงานเกี่ยวกับประเทศญี่ปุ่นที่แสนพิเศษให้ท่านได้ตั้งแต่กระบวนการเริ่มต้นไปจนถึงช่วงเวลาที่
          ทุกท่านได้ชื่นชมและยินดีกับจุดหมายปลายทาง
          <br />
          <br />
          <strong>ดังสโลแกนที่ว่า “Where The Magic Happens as You Wish”</strong>
        </p>
        <div className="image-list">
          <div className="image-list-item grid-item1">
            <img src={AboutUsImg1} alt="about-us-img" />
          </div>
          <div className="image-list-item grid-item2">
            <img src={AboutUsImg2} alt="about-us-img" />
          </div>
          <div className="image-list-item grid-item2">
            <img src={AboutUsImg3} alt="about-us-img" />
          </div>
        </div>
      </div>
      <div className="employee-container">
        <h1>Experience Specialists</h1>
        <div className="employee-list">{generateEmployeeItem()}</div>
      </div>
    </div>
  );
}
