import React, { useState, useEffect } from "react";
import "./Gallery.css";
import * as constantVar from "../../Constant";

export default function Gallery() {
  const [gallery, set_gallery] = useState([]);
  const [categories, set_categories] = useState([]);
  const [current_index, set_current_index] = useState(1);
  const [prev_display, set_prev_display] = useState("block");
  const [next_display, set_next_display] = useState("block");
  let categories_items = [];
  categories.forEach((category) => {
    categories_items.push(
      <div
        className="category-item"
        key={category.id}
        onClick={() => changeCategories(category.id)}
      >
        <span>{category.name}</span>
      </div>
    );
  });

  function prevImg() {
    if (current_index === 1) {
      set_prev_display("none");
    } else {
      set_prev_display("block");
      set_next_display("block");
    }
    if (current_index !== 0) {
      set_current_index(current_index - 1);
    }
  }
  function nextImg() {
    if (current_index === gallery.length - 2) {
      set_next_display("none");
    } else {
      set_next_display("block");
      set_prev_display("block");
    }
    set_current_index(current_index + 1);
  }

  async function changeCategories(categoriesID) {
    let gallery = await getGallery(categoriesID);
    set_gallery(gallery);
    set_current_index(1);
    set_prev_display("block");
    if (gallery.length > 1) {
      set_next_display("block");
    } else {
      set_next_display("none");
    }
  }
  async function getGallery(categoriesID) {
    return await fetch(
      constantVar.backend_url +
        "api/get-gallery?gallery_category_id=" +
        categoriesID
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log("result error", error);
        }
      );
  }
  async function getCategories() {
    return await fetch(constantVar.backend_url + "api/get-categories")
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("result", result);
          return result;
        },
        (error) => {
          console.log("result error", error);
        }
      );
  }
  function generateImgSlide() {
    return (
      <div className="gallery-img-slide">
        <div className="gallery-img-prev" style={{ display: prev_display }}>
          <button onClick={() => prevImg()}>
            <img
              src={constantVar.backend_url + gallery[current_index - 1]}
              alt=""
            />
          </button>
        </div>
        <div className="gallery-img-current">
          <img src={constantVar.backend_url + gallery[current_index]} alt="" />
        </div>
        <div className="gallery-img-next" style={{ display: next_display }}>
          <button onClick={() => nextImg()}>
            <img
              src={constantVar.backend_url + gallery[current_index + 1]}
              alt=""
            />
          </button>
        </div>
      </div>
    );
  }

  async function init() {
    let categories_data = await getCategories();

    set_categories(categories_data);
    set_gallery(await getGallery(categories_data[0].id));
  }
  useEffect(() => {
    init();
  }, []);

  return (
    <div className="content-container">
      <div className="gallery">
        <h1>Gallery</h1>
        <div className="category-list">{categories_items}</div>
        {gallery && gallery.length > 0 && generateImgSlide()}
      </div>
    </div>
  );
}
