import React, { useState, useEffect, useRef } from "react";
import "./ExperienceDetail.css";
import * as constantVar from "../../Constant";
import { useParams } from "react-router-dom";
import { Facebook, Twitter, Link as LinkIcon } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
export default function BlogDetail() {
  const { blogID } = useParams();
  const article_detail_ref = useRef(null);
  const [article, set_article] = useState({});
  const [related_articles, set_related_articles] = useState([]);
  async function get_article() {
    let response = await fetch(
      constantVar.backend_url + "api/get-experience-detail/" + blogID
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log("result error", error);
        }
      );
    set_article(response);
  }
  async function get_related_articles() {
    let response = await fetch(
      constantVar.backend_url + "api/get-related-experiences/" + blogID
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log("result error", error);
        }
      );
    set_related_articles(response);
  }
  function generateTagItems(tag_list) {
    let tag_items = [];
    tag_list.forEach((tag) => {
      tag_items.push(
        <Link
          className="tag-item"
          key={tag.id}
          to={"/experience?tag_id=" + tag.id}
        >
          {/* <button > */}
          {tag.name}
          {/* </button> */}
        </Link>
      );
    });
    return tag_items;
  }

  function generateArticleItems() {
    return related_articles.map((article) => (
      <div className="article-item">
        <Link to={"/experience-detail/" + article.id}>
          <img src={article.cover_img_path} alt="" />
        </Link>
        <div className="article-item-detail">
          <p className="article-date">{article.article_date}</p>

          <Link to={article.id} className="article-title">
            {article.title}
          </Link>
          <div className="tag-list">{generateTagItems(article.tag_list)}</div>
        </div>
      </div>
    ));
  }
  useEffect(() => {
    article_detail_ref.current.scrollIntoView();
    get_article();
    get_related_articles();
  }, [blogID]);
  return (
    <div>
      <div className="header-bg" ref={article_detail_ref}></div>
      <div className="article-detail-container">
        <img
          className="article-detail-cover-img"
          src={article.cover_img_path}
          alt=""
        />
        <div className="content-zone">
          <div className="article-content">
            <p className="article-date">{article.article_date}</p>
            <p className="article-title">{article.title}</p>
            <div
              className="article-detail"
              dangerouslySetInnerHTML={{ __html: article.detail }}
            ></div>
            <div>
              <div className="tag-list">
                {article.tag_list &&
                  article.tag_list.length > 0 &&
                  generateTagItems(article.tag_list)}
              </div>
            </div>
          </div>
          {/* <div style={{ fontSize: "40px", position: "absolute" }}>TEST</div> */}
          <div className="share-content">
            <div className="sticky-share">
              <span>SHARE</span>
              <div className="share-list">
                <FacebookShareButton
                  url={window.location.href}
                  quote={article.title}
                  description={article.intro}
                >
                  <Facebook></Facebook>
                </FacebookShareButton>
                <TwitterShareButton
                  title={article.title}
                  url={window.location.href}
                >
                  <Twitter></Twitter>
                </TwitterShareButton>
                {/* <button>
                  <LinkIcon></LinkIcon>
                </button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="related-article-list">
          {related_articles &&
            related_articles.length > 0 &&
            generateArticleItems()}
        </div>
      </div>
    </div>
  );
}
