import React from "react";
import Logo from "../assets/logo/Logo_Black.png";
import YoutubeIcon from "../assets/icon-images/youtube.png";
import FacebookIcon from "../assets/icon-images/facebook.png";
import InstagramIcon from "../assets/icon-images/instagram.png";
import LineIcon from "../assets/icon-images/line.png";
export default function Footer() {
  return (
    <div className="footer">
      <div className="contact-bar">
        <div className="footer-item">
          <img src={Logo} alt="" width="200px" />
        </div>
        <div className="footer-item">
          <span>
            ช่องทางการติดต่อ
            <br />
            บริษัท โอมาคาเสะ โวยาจ จำกัด
            <br />
            43-45 ซอยสวนหลวง 2 <br />
            แขวงวังใหม่ เขตปทุมวัน
            <br /> กรุงเทพฯ 10330
          </span>
        </div>
        <div className="footer-item">
          <span>
            Contact Information
            <br />
            Omakase Voyage Co., Ltd. <br />
            43-45 Suanluang Soi 2 <br />
            Wangmai Subdistrict, Pathumwan District
            <br /> Bangkok 10330
          </span>
        </div>
        <div className="footer-item">
          <span>
            Email: omakasevoyage@gmail.com
            <br />
            Phone :
            <br />
            +66-94-995-5555 (Thai)
            <br />
            <br />
            ใบอนุญาตประกอบธุรกิจนำเที่ยวเลขที่ 11/10917
            <br />
            TAT License number 11/10917
          </span>
          <br />
          <div className="social-bar">
            <a
              href="
https://www.instagram.com/omakasevoyage/"
            >
              <img className="instagram-icon" src={InstagramIcon} alt="" />
            </a>
            <a
              href="
https://www.facebook.com/omakasevoyage"
            >
              <img className="facebook-icon" src={FacebookIcon} alt="" />
            </a>
            <a
              href="
https://www.facebook.com/sugoisho"
            >
              <img className="facebook-icon" src={FacebookIcon} alt="" />
            </a>

            <a href="https://lin.ee/wSKR3wA">
              <img className="line-icon" src={LineIcon} alt="" />
            </a>
            {/* <img className="youtube-icon" src={YoutubeIcon} alt="" /> */}
          </div>
        </div>
      </div>

      {/* <div className="row social-bar">
        <img className="instagram-icon" src={InstagramIcon} alt="" />
        <img className="facebook-icon" src={FacebookIcon} alt="" />
        <img className="line-icon" src={LineIcon} alt="" />
        <img className="youtube-icon" src={YoutubeIcon} alt="" />
      </div> */}
    </div>
  );
}
