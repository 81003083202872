import React, { useState, useEffect } from "react";
import "./OurService.css";
import * as constantVar from "../../Constant";
import { Link } from "react-router-dom";

export default function OurService() {
  const [service_list, set_service_list] = useState([]);
  async function init() {
    set_service_list(await getServiceList());
  }
  async function getServiceList() {
    return await fetch(constantVar.backend_url + "api/get-service-list")
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log("result error", error);
        }
      );
  }
  function generateLearnMore(link) {
    if (link !== "" && link !== null) {
      // return (
      //   <Link to={link}>
      //     <button className="learnMore">Learn more</button>
      //   </Link>
      // );
    } else return;
  }
  function generateServiceList() {
    //generate service list saperate 2 columns of title and image switch by odd and even
    let service_items = [];
    service_list.forEach((service, index) => {
      if (index % 2 === 0) {
        service_items.push(
          <div className="ourservice-item" style={{ marginTop: "0" }}>
            <div className="col collapseImageA">
              <img
                className="collapseImageBig"
                src={service.main_img_path}
                alt="service image"
              />
            </div>
            <div className="ourservice-item-contentA">
              <div className="ourservice-item-title-container title-1">
                <img src={service.title_img_path} alt="" width="100%" />
                {/* <p className="ourservice-item-titleA">Business</p>
              <p className="ourservice-item-title-quentinA">Coordinator</p> */}
              </div>
              <p className="ourservice-item-desc">{service.desription}</p>
              {generateLearnMore(service.link)}
            </div>
          </div>
        );
      } else {
        service_items.push(
          <div className="ourservice-item">
            <div className="ourservice-item-contentB">
              <div className="ourservice-item-title-container title-2">
                <img src={service.title_img_path} alt="" width="100%" />
                {/* <p className="ourservice-item-titleB">Tour</p>
                <p className="ourservice-item-title-quentinB">Operator</p> */}
              </div>
              <p className="ourservice-item-desc">{service.desription}</p>
              {generateLearnMore(service.link)}
            </div>
            <div className="col collapseImageB">
              <img
                className="collapseImageBig"
                src={service.main_img_path}
                alt="aimg"
              />
            </div>
          </div>
        );
      }
    });
    return service_items;
  }
  useEffect(() => {
    init();
  }, []);

  return (
    <div className="app-background-container our-service-bg">
      <div className="app-content-container">
        <h1 className="title">Our Service</h1>
        {service_list && service_list.length > 0 && generateServiceList()}
      </div>
    </div>
  );
}
