import React from "react";
import NavigationBar from "./Components/NavigationBar";
import Footer from "./Components/Footer";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="app">
      <Helmet>
        <title>Omakase Voyage</title>
      </Helmet>
      <NavigationBar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
