import React, { useState, useEffect, useRef } from "react";
import "./Blog.css";
import * as constantVar from "../../Constant";
import { Link, useSearchParams } from "react-router-dom";

export default function Blog() {
  // let highlight_article = {};
  const [searchParams] = useSearchParams();
  const article_ref = useRef(null);
  const [popular_tag, set_popular_tag] = useState([]);
  const [article_list, set_article_list] = useState([]);
  const [highlight_article, set_highlight_article] = useState({});
  const [tag_id, set_tag_id] = useState("");
  const [keyword, set_keyword] = useState("");
  const [order_by, set_order_by] = useState("created_at");
  const [page, set_page] = useState(1);
  const [input_page, set_input_page] = useState(1);

  async function getPopularTags() {
    return await fetch(constantVar.backend_url + "api/get-popular-tags")
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log("result error", error);
        }
      );
  }

  async function getArticles() {
    let response = await fetch(
      constantVar.backend_url +
        "api/get-articles?page=" +
        page +
        "&tag_id=" +
        tag_id +
        "&order_by=" +
        order_by +
        "&keyword=" +
        keyword
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log("result error", error);
        }
      );
    set_article_list(response);
  }

  async function getHighligthArticle() {
    return await fetch(constantVar.backend_url + "api/get-highlight-article")
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log("result error", error);
        }
      );
  }

  function generateTagItems(tag_list) {
    let tag_items = [];
    tag_list.forEach((tag) => {
      tag_items.push(
        <button
          className="tag-item"
          key={tag.id}
          onClick={() => clickTag(tag.id)}
        >
          {tag.name}
        </button>
      );
    });
    return tag_items;
  }

  function generateArticleItems() {
    return article_list.data.map((article) => (
      <div className="article-item" key={article.id}>
        <Link to={"/blog-detail/" + article.id}>
          <img src={article.cover_img_path} alt="" />
        </Link>
        <div className="article-item-detail">
          <p className="article-date">{article.article_date}</p>

          <Link to={"/blog-detail/" + article.id} className="article-title">
            {article.title}
          </Link>
          <div className="tag-list">{generateTagItems(article.tag_list)}</div>
        </div>
      </div>
    ));
  }

  function clickTag(tag_id) {
    set_tag_id(tag_id);
    set_page(1);
  }

  function changeSort(sort_by) {
    set_order_by(sort_by);
    set_page(1);
  }

  async function init() {
    if (searchParams.get("tag_id")) {
      set_tag_id(searchParams.get("tag_id"));
    }
    if (searchParams.get("keyword")) {
      set_keyword(searchParams.get("keyword"));
    }
    // getArticles();
    // set_article_list(await getArticles());
    set_popular_tag(await getPopularTags());
    set_highlight_article(await getHighligthArticle());
  }
  useEffect(() => {
    getArticles();
    set_input_page(page);
    // article_ref.current.scrollIntoView();
  }, [page, tag_id, order_by, keyword]);
  useEffect(() => {
    init();
  }, []);

  return (
    <div className="app-background-container">
      <div className="header-bg"></div>
      {/* div display if dont have keyword */}
      {keyword === "" && (
        <div className="highlight-article">
          <img src={highlight_article.cover_img_path} alt="" />
          <div className="highlight-article-detail">
            <p className="highlight-date">{highlight_article.article_date}</p>
            <h1 className="highlight-title">{highlight_article.title}</h1>
            <p className="highlight-intro">{highlight_article.intro}</p>

            <div className="tag-list">
              {highlight_article.tag_list &&
                highlight_article.tag_list.length > 0 &&
                generateTagItems(highlight_article.tag_list)}
            </div>
            <Link to={"/blog-detail/" + highlight_article.id}>
              <button className="read-article-btn">Read Article</button>
            </Link>
          </div>
        </div>
      )}

      <div className="article-container" ref={article_ref}>
        <div className="filter-container">
          <div className="popular-tag">
            <p>TAG ยอดนิยม: </p>
            <div className="tag-list">{generateTagItems(popular_tag)}</div>
          </div>
          <div className="sort-by">
            <label htmlFor="sort_by"> SORT BY</label>
            <select
              name="sort_by"
              id="sort_by"
              onChange={(e) => changeSort(e.target.value)}
            >
              <option value="created_at">LAST UPDATE</option>
              <option value="view_count">MOST VIEW</option>
            </select>
          </div>
        </div>
        <div className="article-list">
          {article_list.data &&
            article_list.data.length > 0 &&
            generateArticleItems()}
        </div>

        <div className="paginator">
          <button
            disabled={page === 1}
            onClick={() => set_page((page) => page - 1)}
          >
            {" "}
            &#60;{" "}
          </button>
          <input
            type="text"
            value={input_page}
            onChange={(e) => set_input_page(e.target.value)}
            onKeyUp={(e) => e.key === "Enter" && set_page(input_page)}
          />
          <button
            disabled={page >= article_list.last_page}
            onClick={() => set_page((page) => page + 1)}
          >
            {" "}
            &#62;{" "}
          </button>
          <span>
            Page {page} Of {article_list.last_page}
          </span>
        </div>
      </div>
    </div>
  );
}
